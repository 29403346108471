import React, { FunctionComponent } from "react";
import { Button, Modal } from "react-bootstrap";

interface Props {
    show: boolean;
    title?: string;
    body?: string;
    acceptButtonCaption?: string;
    cancelButtonCaption?: string;
    onClickCancel: () => void;
    onClickAccept: () => void;
}
const ConfirmationModal: FunctionComponent<Props> = props => {
    return (
        <Modal show={props.show}
            onHide={props.onClickCancel}
            backdrop="static"
            keyboard={false}>

            <Modal.Header closeButton>
                <Modal.Title>{props.title || "Delete Confirmation"}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                {props.body || "Are you sure you want to delete this?"}
            </Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={props.onClickCancel}>
                    {props.cancelButtonCaption || "No"}
                </Button>
                <Button variant="danger" onClick={props.onClickAccept}>
                    {props.acceptButtonCaption || "Yes"}
                </Button>
            </Modal.Footer>
        </Modal>
    );
}

export default ConfirmationModal;