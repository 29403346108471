import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { ActionModel, ScopeModel, UserModel } from "@medo/sso-libs";

interface AppUserState {
    currentUser: UserModel
}

const initialCurrentUserState = {
    id: "",
    username: "",
    password: "",
    scopes: []
}

const initialState: AppUserState = {
    currentUser: initialCurrentUserState
}

const userSlice = createSlice({
    name: "user",
    initialState,
    reducers: {
        updateCurrentUser(state, action: PayloadAction<UserModel>) {
            state.currentUser = action.payload;
        },
        resetCurrentUser(state) {
            state.currentUser = initialCurrentUserState;
        },
        addScope(state, action: PayloadAction<ScopeModel>){
            if(state.currentUser.scopes == null) state.currentUser.scopes = [action.payload];
            else state.currentUser.scopes?.push(action.payload);
        },
        deleteScope(state, action: PayloadAction<string>){
            state.currentUser.scopes = state.currentUser.scopes?.filter(s => s.id !== action.payload);
        },
        addAction(state, action: PayloadAction<[string, ActionModel]>){
            const [scopeId, actionModel] = action.payload;
            const scope = state.currentUser.scopes?.find(x => x.id === scopeId);
            if(scope == null) return;
            if(scope.actions == null) {
                scope.actions = [actionModel];
            } else {
                scope.actions.push(actionModel);
            }
        },
        editAction(state, action: PayloadAction<ActionModel>){
            state.currentUser.scopes?.forEach(scope => {
                const _action = scope.actions?.find(x => x.id === action.payload.id);
                if(_action) {
                    _action.method = action.payload.method;
                    _action.target = action.payload.target;
                }
            });
        },
        deleteAction(state, action: PayloadAction<string>){
            state.currentUser.scopes?.forEach(scope =>
                              scope.actions = scope.actions?.filter(x => x.id !== action.payload));
        }
    }
});

export const userActions = userSlice.actions;
export const userReducer = userSlice.reducer;