import { createSlice, PayloadAction } from "@reduxjs/toolkit";


interface AppUIState {
    errorMessage?: string
}
const initialState: AppUIState = {
    errorMessage: undefined
}


const uiSlice = createSlice({
    name: "ui-slice",
    initialState,
    reducers: {
        setError(state, action: PayloadAction<string>){
            state.errorMessage = action.payload;
        },
        removeError(state){
            state.errorMessage = undefined;
        }
    }
})

export const uiActions = uiSlice.actions;
export const uiReducer = uiSlice.reducer;