import React, { useState } from "react"
import { Alert } from "react-bootstrap"

interface Props {
    message?: string
    onClickClose: () => void
}

const ErrorElement: React.FC<Props> = props => {
    const [show, setShow] = useState(true);

    const closeAlert = () => {
        props.onClickClose();
        setShow(false);
    }

    const errorMessage = props.message ? props.message : "You are not authorized to do this"
    const alert = show ? (
        <Alert className="m-2" variant="danger" onClose={closeAlert} dismissible>
            {errorMessage}
        </Alert>
    ) : <></>
    return alert;
}

export default ErrorElement;