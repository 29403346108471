import React, { useEffect, useState } from "react";
import { Accordion, Breadcrumb, Button, Col, Form } from "react-bootstrap";
import ScopeElement from "./ScopeElement/ScopeElement";
import UsernameForm from "./UsernameForm/UsernameForm";
import Repository from "../../common/Repository";
import ConfirmationModal from "../ConfirmationModal/ConfirmationModal";
import { useHistory, useParams } from "react-router-dom";
import { ScopeModel } from "@medo/sso-libs";
import axios from "axios";
import { AppDispatch, RootState } from "../../store/store";
import { userActions } from '../../store/userSlice'
import { useDispatch, useSelector } from "react-redux";
import { uiActions } from "../../store/uiSlice";


const UserForm: React.FC = () => {
    const { id } = useParams<{ id: string }>();
    const [selectedScope, setSelectedScope] = useState("Select Scope");
    const [toBeDeletedUserId, setToBeDeletedUserId] = useState<string | undefined>(undefined);
    const [scopeOptions, setscopeOptions] = useState<string[]>([]);
    const [scopeOptionsErrorMessage, setScopeOptionsErrorMessage] = useState<string | undefined>(undefined);
    const [toBeDeletedScope, setToBeDeletedScope] = useState<ScopeModel | undefined>(undefined);
    const [toBeDeletedActionId, setToBeDeletedActionId] = useState<string | undefined>(undefined);

    const history = useHistory();
    const user = useSelector((state: RootState) => state.user.currentUser);
    const dispatch = useDispatch<AppDispatch>();

    useEffect(() => {
        const userId = id;
        if (userId === "new-user")
        {
            updateScopeOptions();
            dispatch(userActions.updateCurrentUser({...user, id: "new-user"}));
        }
        else
        {
            const fetchUser = async () => {
                const fetchedUser = await Repository.GetUser(userId);
                if (fetchedUser === null)
                {
                    dispatch(userActions.updateCurrentUser({...user, id: "new-user"}));
                }
                else
                {
                    updateScopeOptions(fetchedUser.scopes);
                    dispatch(userActions.updateCurrentUser(fetchedUser));
                }
            };
            fetchUser();
        }
        return () => {
            dispatch(userActions.resetCurrentUser());
        }
    }, [])

    const scopeOnChangeHandler = (event: React.ChangeEvent<HTMLSelectElement>) => {
        setSelectedScope(event.target.value);
    }

    const deleteUserHandler = async () => {
        const userId = toBeDeletedUserId;
        if (userId === undefined) return;
        try {
            await Repository.DeleteUser(userId);
            dispatch(userActions.resetCurrentUser());
            history.push("/users");
        }
        catch (error) {
            if (axios.isAxiosError(error) && error.response?.status === 403) {
                dispatch(uiActions.setError("You are not authorized to delete users"));
            }
            setToBeDeletedUserId(undefined);
        }
    }

    const onClickAddScopeHandler = async () => {
        if (selectedScope === "Select Scope") return;

        const existingScope = user.scopes?.find(r => r.scopeOption.name === selectedScope);
        if (existingScope != null) return;

        try {
            const scope = await Repository.AddScope(user.id, selectedScope);
            dispatch(userActions.addScope(scope));
            setscopeOptions(state => state.filter(x => x !== selectedScope));
        }
        catch (error) {
            if (axios.isAxiosError(error) && error.response?.status === 403) {
                setScopeOptionsErrorMessage("You are not authorized to add scopes");
            }
        }
    }

    const deleteScopeHandler = async () => {
        const scope = toBeDeletedScope;
        if (scope === undefined) return;

        try {
            await Repository.DeleteScope(scope.id);
            dispatch(userActions.deleteScope(scope.id));
            setscopeOptions(state => ([
                ...state,
                scope.scopeOption.name
            ]));
        }
        catch (error) {
            if (axios.isAxiosError(error) && error.response?.status === 403) {
                dispatch(uiActions.setError("You are not authorized to delete scopes"));
            }
        } finally {
            setToBeDeletedScope(undefined);
        }
    }

    const addActionHandler = async (scopeId: string, method: string, target: string) => {
        try {
            const action = await Repository.AddAction(scopeId, method, target);
            dispatch(userActions.addAction([scopeId, action]));
        }
        catch (error) {
            if (axios.isAxiosError(error) && error.response?.status === 403) {
                dispatch(uiActions.setError("You are not authorized to add actions"));
            }
        }
    }

    const editeActionHandler = async (actionId: string, method: string, target: string) => {
        try {
            const responseAction = await Repository.EditAction(actionId, method, target);
            dispatch(userActions.editAction(responseAction));
        }
        catch (error) {
            if (axios.isAxiosError(error) && error.response?.status === 403) {
                dispatch(uiActions.setError("You are not authorized to edit actions"));
            }
        }
    }

    const deleteActionHandler = async () => {
        const actionId = toBeDeletedActionId;
        if (actionId === undefined) return;
        try {
            await Repository.DeleteAction(actionId);
            dispatch(userActions.deleteAction(actionId));
        }
        catch (error) {
            if (axios.isAxiosError(error) && error.response?.status === 403) {
                dispatch(uiActions.setError("You are not authorized to delete actions"));
            }
        } finally {
            setToBeDeletedActionId(undefined);
        }
    }

    const updateScopeOptions = (scopes?: ScopeModel[]) => {
        const userScopes = scopes?.map(x => x.scopeOption.name);
        Repository.GetScopeOptions().then(options => {
            options.sort((a, b) => a.createdAt! - b.createdAt!);
            const initialScopes = options.map(x => x.name);
            const scopesList = initialScopes.filter(x => !userScopes?.includes(x));
            setscopeOptions(scopesList);
        }).catch(error => {
            if (axios.isAxiosError(error) && error.response?.status === 403) {
                setScopeOptionsErrorMessage("You are not authorized to view the list of scopes");
            }
        })
    }


    const sortedScopes = [...user.scopes || []];
    if (sortedScopes.length > 0) sortedScopes.sort((a, b) => a.createdAt! - b.createdAt!);
    const scopeElements = sortedScopes?.map(s =>
        <ScopeElement key={s.id} scope={s}
            addAction={addActionHandler}
            deleteAction={(actionId: string) => setToBeDeletedActionId(actionId)}
            editeAction={editeActionHandler}
            onClickDeleteScope={(scope: ScopeModel) => setToBeDeletedScope(scope)} />
    );

    const scopeOptionElements = scopeOptions.map(option => {
        return <option key={option}>{option}</option>
    });

    const scopeControls = user.id === "new-user" ?
        undefined :
        (
            <>
                <Form.Group controlId="exampleForm.ControlSelect1">
                    <Form.Label>Select Scope</Form.Label>
                    <Form.Row>
                        <Col xs="auto">
                            <Form.Control as="select" value={selectedScope}
                                onChange={scopeOnChangeHandler}>
                                <option>Select Scope</option>
                                {scopeOptionElements}
                            </Form.Control>
                        </Col>
                        <Col xs="auto">
                            <Button variant="primary"
                                onClick={onClickAddScopeHandler}
                                disabled={selectedScope === "Select Scope"}>Add</Button>
                        </Col>
                    </Form.Row>
                    {scopeOptionsErrorMessage ?
                        <p className="text-danger">{scopeOptionsErrorMessage}
                            <Button variant="link"
                                className="text-danger"
                                size="sm"
                                onClick={() => setScopeOptionsErrorMessage(undefined)}>X</Button>
                        </p> : <></>}
                </Form.Group>
                <Accordion>
                    {scopeElements}
                </Accordion>
            </>
        );

    return user.id.length === 0 ?
        <div></div> :
        <>
            <ConfirmationModal show={toBeDeletedScope !== undefined}
                onClickAccept={deleteScopeHandler}
                onClickCancel={() => setToBeDeletedScope(undefined)} />

            <ConfirmationModal show={toBeDeletedActionId !== undefined}
                onClickAccept={deleteActionHandler}
                onClickCancel={() => setToBeDeletedActionId(undefined)} />

            <ConfirmationModal show={toBeDeletedUserId !== undefined}
                onClickAccept={deleteUserHandler}
                onClickCancel={() => setToBeDeletedUserId(undefined)} />
            <Breadcrumb>
                <Breadcrumb.Item onClick={() => history.push("/users")}>Users</Breadcrumb.Item>
                <Breadcrumb.Item active>
                    {user.username.length > 0 ? user.username : "New User"}
                </Breadcrumb.Item>
            </Breadcrumb>

            <Form className="mt-5">
                <UsernameForm onDelete={() => setToBeDeletedUserId(user.id)}/>
                {scopeControls}
            </Form>
        </>
}

export default UserForm;