import React, { useState, useEffect } from "react"
import { Breadcrumb, Button, Col, FormControl, InputGroup, ListGroup, Row } from "react-bootstrap"
import { useParams } from "react-router"
import Repository from "../../common/Repository"
import { useHistory } from "react-router-dom";
import { AccessTokenModel, UserModel } from "@medo/sso-libs";
import ConfirmationModal from "../ConfirmationModal/ConfirmationModal";
import axios from "axios";
import { useDispatch } from "react-redux";
import { AppDispatch, RootState } from "../../store/store";
import { uiActions } from "../../store/uiSlice";

const AccessTokenElement: React.FC = () => {
    const [user, setUser] = useState<UserModel | null>(null);
    const [accessTokens, setAccessTokens] = useState<AccessTokenModel[]>([]);
    const [toBeDeletedTokenId, setToBeDeletedTokenId] = useState<string | undefined>(undefined);
    const [tokenDescription, setTokenDescription] = useState<string>("");
    const dispatch = useDispatch<AppDispatch>();
    const { id } = useParams<{ id: string }>();
    const history = useHistory();

    useEffect(() => {
        fetchAccessTokens();
        fetchUser();
    }, [])


    const onChangeTokenDescription = (event: React.ChangeEvent<HTMLInputElement>) => {
        setTokenDescription(event.target.value);
    }

    const onClickGenerateAccessTokenHandler = async () => {
        try {
            const description = tokenDescription.length > 0 ? tokenDescription : "[UNNAMED]";
            const tokenModel = await Repository.GenerateNewAccessToken(id, description);
            if (tokenModel.token == null) return;
            setTokenDescription("");
            fetchAccessTokens({ id: tokenModel.id, token: tokenModel.token });
        } catch (error) {
            if (axios.isAxiosError(error) && error.response?.status === 403) {
                dispatch(uiActions.setError("You are not authorized to create access tokens"));
            }
        }
    }

    const onClickSetTokenToBeDeleted = (accessTokenId: string) => {
        setToBeDeletedTokenId(accessTokenId);
    }

    const onClickUnsetTokenToBeDeleted = () => {
        setToBeDeletedTokenId(undefined);
    }

    const onClickDeleteAccessTokenHandler = async () => {
        try {
            if (toBeDeletedTokenId === undefined) return;
            const isDeleted = await Repository.DeleteAccessToken(toBeDeletedTokenId);
            if (isDeleted) fetchAccessTokens();
        } catch (error) {
            if (axios.isAxiosError(error) && error.response?.status === 403) {
                dispatch(uiActions.setError("You are not authorized to delete access tokens"));
            }
        } finally {
            setToBeDeletedTokenId(undefined);
        }
    }

    const fetchAccessTokens = async (oneTimeToken?: { id: string, token: string }) => {
        try {
            const fetchedTokens = await Repository.GetAccessTokens(id);
            const updatedTokens = fetchedTokens.map(x => {
                if (x.id === oneTimeToken?.id) x.token = oneTimeToken?.token;
                return x;
            }).sort((a, b) => b.createdAt! - a.createdAt!);

            setAccessTokens(updatedTokens ?? []);
        } catch (error) {
            if (axios.isAxiosError(error) && error.response?.status === 403) {
                dispatch(uiActions.setError("You are not authorized to view access tokens"));
            }
        }
    }

    const fetchUser = async () => {
        const userModel = await Repository.GetUser(id);
        setUser(userModel);
    }

    const tokens = accessTokens.length > 0 ? accessTokens.map(accessToken => {
        const tokenDetails = accessToken.token ?
            <>
                <span><strong>{accessToken.description}</strong></span>
                <br />
                <span className="text-warning"><small><em>{accessToken.token}</em></small></span>
            </> :
            <span>{accessToken.description}</span>;
        return (
            <ListGroup.Item key={accessToken.id}>
                <Row>
                    <Col>
                        {tokenDetails}
                    </Col>
                    <Col>
                        <Button className="right"
                            variant="danger"
                            onClick={onClickSetTokenToBeDeleted.bind(null, accessToken.id)}
                        >Delete</Button>
                    </Col>
                </Row>
            </ListGroup.Item>
        )
    }) : <p className="text-center">No Access Tokens Found 😴</p>

    return <>
        <ConfirmationModal show={toBeDeletedTokenId !== undefined}
            body="Any applications using this token will no longer be able to access MEDO resources. You cannot undo this action."
            onClickAccept={onClickDeleteAccessTokenHandler}
            onClickCancel={onClickUnsetTokenToBeDeleted} />

        <Breadcrumb>
            <Breadcrumb.Item onClick={() => history.push(`/users`)}>Users</Breadcrumb.Item>
            <Breadcrumb.Item onClick={() => history.push(`/users/${id}`)}>{user?.username ?? ""}</Breadcrumb.Item>
            <Breadcrumb.Item active>Access Tokens</Breadcrumb.Item>
        </Breadcrumb>

        <Row>
            <Col></Col>
            <Col>
                <InputGroup>
                    <FormControl
                        placeholder="Access Token Description"
                        aria-label="Access Token Description"
                        aria-describedby="basic-addon2"
                        value={tokenDescription}
                        onChange={onChangeTokenDescription}
                    />
                    <InputGroup.Append>
                        <Button variant="primary"
                            onClick={onClickGenerateAccessTokenHandler}>Generate Access Token</Button>
                    </InputGroup.Append>
                </InputGroup>
            </Col>
        </Row>
        <ListGroup className="mt-5">
            {tokens}
        </ListGroup>
    </>
}

export default AccessTokenElement;