import axios from "axios";
import React, { useEffect, useState } from "react";
import { Breadcrumb, Button, Form, Table } from "react-bootstrap";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router";
import { ScopeOptionModel } from "@medo/sso-libs";
import Repository from "../../common/Repository";
import { AppDispatch } from "../../store/store";
import { uiActions } from "../../store/uiSlice";
import ConfirmationModal from "../ConfirmationModal/ConfirmationModal";

interface ScopeOptionState {
    newScopeName?: string
    editedScopeName?: string
    editedScopeId?: string,
    toBeDeletedScopeId?: string,
    newScopeAlreadyExist: boolean,
    editedScopeAlreadyExist: boolean
}

const ScopeOptionElement: React.FC = () => {

    const dispatch = useDispatch<AppDispatch>();
    const [scopeOptions, setScopeOptions] = useState<ScopeOptionModel[]>([])
    const [state, setState] = useState<ScopeOptionState>({
        newScopeAlreadyExist: false,
        editedScopeAlreadyExist: false,
    })
    const history = useHistory();

    useEffect(() => {
        updateScopeOptionList();
    }, [])

    const onChangeHandler = (event: React.ChangeEvent<HTMLInputElement>) => {
        setState(preState => ({
            ...preState,
            [event.target.name]: event.target.value
        }));
    }

    const onClickAddHandler = async () => {
        const scopeName = state.newScopeName;
        if (scopeName === undefined || scopeName.length === 0) return;
        if (!await Repository.IsAppScopeAvailable(scopeName)) {
            setState(preState => ({
                ...preState,
                newScopeAlreadyExist: true
            }));
            return;
        }
        try {
            await Repository.AddScopeOption(scopeName);
            setState(preState => ({
                ...preState,
                newScopeName: undefined,
                newScopeAlreadyExist: false
            }));
            updateScopeOptionList();
        } catch (error) {
            if (axios.isAxiosError(error) && error.response?.status === 403) {
                dispatch(uiActions.setError("You are not authorized to add scope options"));
            }
        }
    }

    const onClickDeleteHandler = (toBeDeletedScopeId: string) => {
        setState(preState => ({
            ...preState,
            toBeDeletedScopeId
        }));
    }

    const onDeleteDismissedHandler = () => {
        setState(preState => ({
            ...preState,
            toBeDeletedScopeId: undefined
        }));
    }

    const onDeleteAcceptedHandler = async () => {
        const scopeId = state.toBeDeletedScopeId;
        if (scopeId === undefined) return;
        try {
            await Repository.DeleteScopeOption(scopeId);
            updateScopeOptionList();
        } catch (error) {
            if (axios.isAxiosError(error) && error.response?.status === 403) {
                dispatch(uiActions.setError("You are not authorized to delete scope options"));
            }
        } finally {
            setState(preState => ({
                ...preState,
                toBeDeletedScopeId: undefined
            }));
        }
    }

    const onClickEditHandler = (scope: ScopeOptionModel) => {
        setState(preState => ({
            ...preState,
            editedScopeId: scope.id,
            editedScopeName: scope.name
        }));
    }

    const onClickCancelHandler = () => {
        setState(preState => ({
            ...preState,
            editedScopeId: undefined
        }));
    }

    const onClickSaveHandler = async (scopeId: string) => {
        const scopeName = state.editedScopeName?.trim();
        if (scopeName === undefined || scopeName.length === 0) return
        if (!await Repository.IsAppScopeAvailable(scopeName)) {
            setState(preState => ({
                ...preState,
                editedScopeAlreadyExist: true
            }));
            return;
        }
        try {
            await Repository.EditScopeOption(scopeId, scopeName);
            setState(preState => ({
                ...preState,
                editedScopeAlreadyExist: false,
                editedScopeId: undefined,
                editedScopeName: undefined
            }));
            updateScopeOptionList();
        } catch (error) {
            if (axios.isAxiosError(error) && error.response?.status === 403) {
                dispatch(uiActions.setError("You are not authorized to edit scope options"));
            }
        }
    }

    const updateScopeOptionList = () => {
        Repository.GetScopeOptions().then(options => {
            options.sort((a, b) => a.createdAt! - b.createdAt!);
            setScopeOptions(options);
        }).catch(error => {
            if (axios.isAxiosError(error) && error.response?.status === 403) {
                dispatch(uiActions.setError("You are not authorized to view the list of scope options"));
            }
        })
    }

    const tableRows = scopeOptions.length < 1 ? undefined : scopeOptions.map(scope => {
        const editedLayout = (
            <tr key={scope.id}>
                <td>
                    <Form.Group controlId="Scope name">
                        <Form.Control type="text"
                            name="editedScopeName"
                            placeholder="Scope name"
                            isInvalid={state.editedScopeAlreadyExist === true}
                            value={state.editedScopeName ?? ""}
                            onChange={onChangeHandler} />
                        <Form.Control.Feedback type="invalid">
                            Scope Option Already Exist
                        </Form.Control.Feedback>
                    </Form.Group>
                </td>
                <td>
                    <Button className="mr-2"
                        variant="primary"
                        onClick={onClickSaveHandler.bind(null, scope.id)}>Save</Button>
                    <Button variant="secondary"
                        onClick={onClickCancelHandler}>Cancel</Button>
                </td>
            </tr>
        );
        const viewLayout = (
            <tr key={scope.id}>
                <td>{scope.name}</td>
                <td>
                    <Button className="mr-2"
                        variant="primary"
                        onClick={onClickEditHandler.bind(null, scope)}>Edit</Button>
                    <Button variant="outline-danger"
                        onClick={onClickDeleteHandler.bind(null, scope.id)}>Delete</Button>
                </td>
            </tr>
        );
        return state.editedScopeId === scope.id ? editedLayout : viewLayout;
    });

    return (
        <>
            <ConfirmationModal show={state.toBeDeletedScopeId !== undefined}
                onClickAccept={onDeleteAcceptedHandler}
                onClickCancel={onDeleteDismissedHandler} />
            <Breadcrumb>
                <Breadcrumb.Item onClick={() => history.push('/users')}>Users</Breadcrumb.Item>
                <Breadcrumb.Item active>Scope Options</Breadcrumb.Item>
            </Breadcrumb>

            <Form className="mt-5">
                <Table className="scope-management-table" striped bordered hover variant="dark">
                    <thead>
                        <tr>
                            <th>Scope Name</th>
                            <th className="edit">Edit</th>
                        </tr>
                    </thead>
                    <tbody>
                        {tableRows}
                        <tr>
                            <td>
                                <Form.Group controlId="Scope name">
                                    <Form.Control type="text"
                                        name="newScopeName"
                                        placeholder="Scope name"
                                        isInvalid={state.newScopeAlreadyExist === true}
                                        value={state.newScopeName ?? ""}
                                        onChange={onChangeHandler}
                                        required />
                                    <Form.Control.Feedback type="invalid">
                                        Scope Option Already Exist
                                </Form.Control.Feedback>
                                </Form.Group>
                            </td>
                            <td>
                                <Button variant="primary" onClick={onClickAddHandler}>Add</Button>
                            </td>
                        </tr>
                    </tbody>
                </Table>
            </Form>
        </>
    );
}

export default ScopeOptionElement;