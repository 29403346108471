import axios from "axios";
import React, { useState } from "react";
import { Button, Form, Modal } from "react-bootstrap";
import { UserModel } from "@medo/sso-libs";
import Repository from "../../../../common/Repository";

interface Props {
    show: boolean
    userId: string
    dismiss: () => void
}

const ChangePasswordModal: React.FC<Props> = props => {
    const [passwords, setPasswords] = useState({
        currentPassword: "",
        newPassword: "",
        confirmPassword: ""
    });
    const [errors, setErrors] = useState({
        currentPasswordError: "",
        confirmPasswordError: "",
        newPasswordError: "",
        authorizationError: ""
    });

    const onChangeInputHandler = (event: React.ChangeEvent<HTMLInputElement>) => {
        setPasswords(state => ({
            ...state,
            [event.target.name]: event.target.value
        }));
    };

    const onClickChangePasswordHandler = async () => {
        const userId = props.userId;

        if (passwords.newPassword.length === 0) {
            setErrors(state => ({
                ...state,
                currentPasswordError: "",
                newPasswordError: "New password can't be empty",
                confirmPasswordError: "",
                authorizationError: ""
            }));
            return;
        }

        if (passwords.confirmPassword !== passwords.newPassword) {
            setErrors(state => ({
                ...state,
                currentPasswordError: "",
                newPasswordError: "",
                confirmPasswordError: "Confirm password should match new password",
                authorizationError: ""
            }));
            return;
        }

        try {
            const response = await Repository.ChangePassword(userId, passwords.currentPassword, passwords.newPassword);
            if ((response as UserModel).id !== undefined) {
                props.dismiss();
                setPasswords(state => ({
                    ...state,
                    currentPassword: "",
                    newPassword: "",
                    confirmPassword: "",
                }));
                setErrors(state => ({
                    ...state,
                    currentPasswordError: "",
                    newPasswordError: "",
                    confirmPasswordError: "",
                    authorizationError: ""
                }));
            } else {
                setErrors(state => ({
                    ...state,
                    currentPasswordError: response as string,
                    newPasswordError: "",
                    confirmPasswordError: "",
                    authorizationError: ""
                }));
            }
        } catch (error) {
            if (axios.isAxiosError(error) && error.response?.status === 403) {
                setErrors(state => ({
                    ...state,
                    currentPasswordError: "",
                    newPasswordError: "",
                    confirmPasswordError: "",
                    authorizationError: "You are not authorized to change password"
                }));
            }
        }
    }

    return (
        <Modal show={props.show}
            onHide={props.dismiss}
            backdrop="static"
            keyboard={false}>

            <Modal.Body>
                <Form.Group controlId="currentPassword">
                    <Form.Label>Current Password</Form.Label>
                    <Form.Control type="password"
                        name="currentPassword"
                        value={passwords.currentPassword}
                        isInvalid={errors.currentPasswordError?.length > 0}
                        onChange={onChangeInputHandler}
                        required />
                    <Form.Control.Feedback type="invalid">
                        {errors.currentPasswordError}
                    </Form.Control.Feedback>
                </Form.Group>
                <Form.Group className="mt-4" controlId="newPassword">
                    <Form.Label>New Password</Form.Label>
                    <Form.Control type="password"
                        name="newPassword"
                        value={passwords.newPassword}
                        isInvalid={errors.newPasswordError?.length > 0}
                        onChange={onChangeInputHandler}
                        required />
                    <Form.Control.Feedback type="invalid">
                        {errors.newPasswordError}
                    </Form.Control.Feedback>
                </Form.Group>
                <Form.Group controlId="confirmPassword">
                    <Form.Label>Confirm Password</Form.Label>
                    <Form.Control type="password"
                        name="confirmPassword"
                        isInvalid={errors.confirmPasswordError?.length > 0}
                        value={passwords.confirmPassword}
                        onChange={onChangeInputHandler}
                        required />
                    <Form.Control.Feedback type="invalid">
                        {errors.confirmPasswordError}
                    </Form.Control.Feedback>
                </Form.Group>
                <p className="text-danger">{errors.authorizationError}</p>
            </Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={props.dismiss}>
                    Cancel
                    </Button>
                <Button variant="primary" onClick={onClickChangePasswordHandler}>
                    Change
                    </Button>
            </Modal.Footer>
        </Modal>
    );
}

export default ChangePasswordModal;