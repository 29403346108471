import axios from "axios";
import React, { useState } from "react";
import { Button, Col, Container, Form, Jumbotron, Row } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router";
import Repository from "../../../common/Repository";
import { AppDispatch, RootState } from "../../../store/store";
import { uiActions } from "../../../store/uiSlice";
import { userActions } from "../../../store/userSlice";
import ChangePasswordModal from "./ChangePasswordModal/ChangePasswordModal";

interface Props {
    onDelete: () => void
}

const UsernameForm: React.FC<Props> = props => {

    const user = useSelector((state: RootState) => state.user.currentUser);
    const dispatch = useDispatch<AppDispatch>();
    const history = useHistory();

    const [isPasswordModalActive, setIsPasswordModalActive] = useState(false);
    const [errorMessage, setErrorMessage] = useState("");
    const [isUsernameBeingEdited, setIsUsernameBeingEdited] = useState(false);
    const [preUsername, setPreUsername] = useState<string | undefined>(undefined);

    const AddUserHandler = async () => {
        const _username = user.username.trim();
        const _password = user.password && user.password?.length > 0 ? user.password : undefined;
        if (_username.length < 1) return;

        const isUsernameAvailable = await Repository.IsUsernameAvailable(_username);
        if (!isUsernameAvailable) {
            setErrorMessage("Username is unavailable!");
            return;
        }
        try {
            const fetchedUser = await Repository.AddUser(_username, _password);
            if (fetchedUser) {
                dispatch(userActions.updateCurrentUser(fetchedUser));
                resetForm();
            }
        }
        catch (error) {
            if (axios.isAxiosError(error) && error.response?.status === 403) {
                dispatch(uiActions.setError("You are not authorized to add users"));
            }
        }
    }

    const onSaveEditingHandler = async () => {
        const _username = user.username.trim();
        const userId = user.id;
        if (_username.length < 1 || userId.length < 1) return;
        const isUsernameAvailable = await Repository.IsUsernameAvailable(_username);
        if (!isUsernameAvailable) {
            setErrorMessage("Username is unavailable!");
            return;
        }
        try {
            const fetchedUser = await Repository.EditUser(userId, _username);
            dispatch(userActions.updateCurrentUser(fetchedUser));
            resetForm();
        }
        catch (error) {
            if (axios.isAxiosError(error) && error.response?.status === 403) {
                dispatch(uiActions.setError("You are not authorized to edit users"));
            }
            dispatch(userActions.updateCurrentUser({...user, username: preUsername!}));
        }
    }

    const onClickToggleEditingHandler = () => {
        if(preUsername != null) dispatch(userActions.updateCurrentUser({...user, username: preUsername}));
        const _preUsername = preUsername == null? user.username : undefined;
        setPreUsername(_preUsername);
        setIsUsernameBeingEdited(state => !state);
        setErrorMessage("");
    }

    const resetForm = () => {
        setErrorMessage("");
        setIsUsernameBeingEdited(false);
        setPreUsername(undefined);
    }

    const isNewUser = user.id === "new-user";
    let buttons: JSX.Element;
    if (isNewUser) {
        buttons = <Button variant="primary" onClick={AddUserHandler}>Add User</Button>
    }
    else if (isUsernameBeingEdited) {
        buttons = (
            <>
                <Button className="mr-2" variant="primary" onClick={onSaveEditingHandler}>Save</Button>
                <Button className="mr-2" variant="secondary" onClick={onClickToggleEditingHandler}>Cancel</Button>
            </>
        )
    }
    else {
        buttons = (
            <>
                <Button variant="primary" onClick={onClickToggleEditingHandler}>
                    Edit
                </Button>
                <Button className="ml-2" variant="outline-danger" onClick={props.onDelete}>
                    Delete
                </Button>
                <Button className="right ml-2" variant="primary" onClick={() => setIsPasswordModalActive(true)}>
                    Change Password
                </Button>
                <Button className="right" variant="primary" onClick={() => history.push(`/users/${user.id}/access-tokens`)}>
                    Access Tokens
                </Button>
            </>
        )
    }

    const usernameForm = (
        <>
            <ChangePasswordModal show={isPasswordModalActive}
                userId={user.id}
                dismiss={() => setIsPasswordModalActive(false)} />

            <Row className="username-form-row">
                <Col>
                    <Form.Group controlId="username">
                        <Form.Label>Username / Email</Form.Label>
                        <Form.Control
                            className="username-editable"
                            name="username"
                            value={user.username}
                            onChange={e => dispatch(userActions.updateCurrentUser({...user, username: e.target.value}))}
                            type="text" placeholder="i.e. example@medo.ai"
                            isInvalid={errorMessage.length > 0}
                            required={true}
                            plaintext={!isNewUser && !isUsernameBeingEdited}
                            readOnly={!isNewUser && !isUsernameBeingEdited} />
                        <Form.Control.Feedback type="invalid">
                            {errorMessage}
                        </Form.Control.Feedback>
                    </Form.Group>
                </Col>
                <Col>
                    {!isNewUser ? undefined : (
                        <Form.Group controlId="password" >
                            <Form.Label>Password</Form.Label>
                            <Form.Control
                                value={user.password || ""}
                                name="password"
                                onChange={e => dispatch(userActions.updateCurrentUser({
                                    ...user,
                                    password: e.target.value
                                }))}
                                type="password"
                                placeholder="Password" />
                            <Form.Text className="text-muted">
                                Optional - For non-OAuth logins
                            </Form.Text>
                        </Form.Group>
                    )}
                </Col>
            </Row>
            {buttons}
        </>
    );

    return (
        <Jumbotron fluid className="pt-4 round-corners-5 username-form">
            <Container>
                {usernameForm}
            </Container>
        </Jumbotron>
    )
}

export default UsernameForm;