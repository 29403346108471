import React, { useState } from "react";
import { MedoWidgets } from "@medo/ui-libs";
import { Container } from "react-bootstrap";
import { Switch, Route, Redirect, useHistory } from "react-router-dom";
import UserForm from "./components/UserForm/UserForm";
import Users from "./components/Users/Users";
import Repository from "./common/Repository";
import ScopeOptionElement from "./components/scopeOption/ScopeOption";
import AccessTokenElement from "./components/AccessTokenElement/AccessTokenElement";
import ErrorElement from "./components/ErrorElement/ErrorElement";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "./store/store";
import { uiActions } from "./store/uiSlice";
// Using require here since it is an external
// tslint:disable-next-line
const config = require("envConfig");

/**
 * NOTE: This must be defined in webpackConfig.ts as an external item.
 */
// tslint:disable-next-line
const port = require("ssoPort");
const baseURL = require("baseURL");
if (port == null) {
    throw new Error("ssoPort must be defined in webpack configuration");
}
const ssoPort = parseInt(port, 10);
export const SSO_URL = config === "dev" ? `http://localhost:${ssoPort}` : baseURL;

interface State {
    loggedIn: boolean,
    userInfo: any
}

const App: React.FC = () => {
    const history = useHistory();
    const [state, setState] = useState<State>({
        loggedIn: false,
        userInfo: null
    })
    const errorMessage = useSelector((x: RootState) => x.ui.errorMessage);
    const dispatch = useDispatch<AppDispatch>();

    const handleLogin = async (userInfo: any) => {
        setState({ loggedIn: true, userInfo });
    }

    const handleLogout = async () => {
        const isLoggedOut = await Repository.Logout();
        if (!isLoggedOut) return;
        setState({ loggedIn: false, userInfo: null });
        history.push("/login");
    }

    const handleClickNewUser = () => {
        history.push("/users/new-user");
    }

    const handleClickManageScopes = () => {
        history.push("/scope-options");
    }

    const handleOnClickUserItem = async (userId: string) => {
        const user = await Repository.GetUser(userId);
        if (user === null) return;
        history.push(`/users/${user.id}`);
    }

    return (
        <>
            <MedoWidgets.NavBar appTitle="Medo Single Sign On" username={state.userInfo?.email}>
                <MedoWidgets.DropdownItem title="Logout" onClick={handleLogout} />
            </MedoWidgets.NavBar>

            { errorMessage ? <ErrorElement message={errorMessage}
                onClickClose={() => dispatch(uiActions.removeError())} /> : <></>}

            <Container className="mt-5">
                <Switch>
                    <Route path="/login">
                        {state.loggedIn ?
                            <Redirect to="/users" />
                            :
                            <MedoWidgets.SSOLogin
                                handleLoggedIn={handleLogin}
                                ssoBaseUrl={SSO_URL} />
                        }
                    </Route>
                    <Route path="/users" exact>
                        {state.loggedIn ?
                            <Users
                                onClickNewUser={handleClickNewUser}
                                onClickManageScopes={handleClickManageScopes}
                                onClickUserItem={handleOnClickUserItem} />
                            :
                            <Redirect to="/login" />
                        }
                    </Route>
                    <Route path="/users/:id" exact>
                        <UserForm />
                    </Route>

                    <Route path="/users/:id/access-tokens">
                        <AccessTokenElement />
                    </Route>

                    <Route path="/scope-options">
                        <ScopeOptionElement />
                    </Route>

                    <Route path="/" exact>
                        <Redirect to="/users" />
                    </Route>
                </Switch>
            </Container>
        </>
    );
}

export default App;