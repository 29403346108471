import React, { useState } from "react";
import { Accordion, Button, Card, Col, Form, Row, Table } from "react-bootstrap";
import { ActionModel, ScopeModel } from "@medo/sso-libs";
interface Props {
    scope: ScopeModel
    addAction: (scopeId: string, method: string, target: string) => void
    deleteAction: (actionId: string) => void
    editeAction: (actionId: string, method: string, target: string) => void
    onClickDeleteScope: (scope: ScopeModel) => void
}

const methods: string[] = ["*", "get", "post", "patch", "delete"]

const ScopeElement: React.FC<Props> = props => {

    const [newMethod, setNewMethod] = useState("*");
    const [newTarget, setNewTarget] = useState<string | undefined>(undefined);
    const [editedMethod, setEditedMethod] = useState<string | undefined>(undefined);
    const [editedTarget, setEditedTarget] = useState<string | undefined>(undefined);
    const [editedActionId, setEditedActionId] = useState<string | undefined>(undefined);

    const onEditCanceled = () => {
        setEditedActionId(undefined);
        setEditedMethod(undefined);
        setEditedTarget(undefined);
    }

    const onEditSave = (actionId: string) => {
        props.editeAction(actionId, editedMethod ?? "*", editedTarget ?? "*");
        setEditedActionId(undefined);
        setEditedMethod(undefined);
        setEditedTarget(undefined);
    }

    const addOnClick = () => {
        props.addAction(props.scope.id, newMethod, newTarget || "*");
        setNewMethod("*");
        setNewTarget(undefined);
    }

    const editOnClick = (action: ActionModel) => {
        setEditedActionId(action.id);
        setEditedMethod(action.method);
        setEditedTarget(action.target);
    }

    const deleteScopeHandler = (event: React.MouseEvent<HTMLButtonElement>) => {
        event.stopPropagation();
        props.onClickDeleteScope(props.scope);
    }

    const ActionsToCSV = (actions: ActionModel[]) => {
        let result = "";
        const _sortedActions = [...actions].sort((a,b) => a.createdAt! - b.createdAt!);
        _sortedActions.forEach(action => {
            const method = action.method === '*' ? 'Any' : action.method;
            const target = action.target === '*' ? 'Any' : action.target;
            result = result.length < 1 ? `${method} -> ${target}` : `${result}\n${method} -> ${target}`;
        });
        return result;
    }


    const sortedActions = props.scope.actions?
        [...props.scope.actions].sort((a, b) => a.createdAt! - b.createdAt!) : [];
    const tableRows = sortedActions?.map(action => {
        const editedLayout = (
            <tr key={action.id}>
                <td>
                    <Form.Control as="select" value={editedMethod}
                        onChange={e => setEditedMethod(e.target.value)}>

                        {methods.map(method => <option key={method}>{method}</option>)}
                    </Form.Control>
                </td>
                <td>
                    <Form.Control type="text" placeholder="Target" value={editedTarget ?? ""}
                        onChange={e => setEditedTarget(e.target.value)} />
                </td>
                <td>
                    <Button className="mr-2"
                        variant="primary"
                        onClick={() => onEditSave(action.id)}>Save</Button>
                    <Button variant="secondary" onClick={onEditCanceled}>Cancel</Button>
                </td>
            </tr>
        );
        const viewLayout = (
            <tr key={action.id}>
                <td>{action.method}</td>
                <td>{action.target}</td>
                <td>
                    <Button className="mr-2"
                        variant="primary"
                        onClick={() => editOnClick(action)}>Edit</Button>
                    <Button variant="outline-danger"
                        onClick={() => props.deleteAction(action.id)}>Delete</Button>
                </td>
            </tr>
        );
        return editedActionId === action.id ? editedLayout : viewLayout;
    });

    const actionsTable =
        (
            <Table className="action-table" striped bordered hover variant="dark">
                <thead>
                    <tr>
                        <th className="action">Action</th>
                        <th>Target</th>
                        <th className="edit">Edit</th>
                    </tr>
                </thead>
                <tbody>
                    {tableRows}
                    <tr>
                        <td>
                            <Form.Control as="select"
                                value={newMethod}
                                onChange={e => setNewMethod(e.target.value)}>

                                {methods.map(action => <option key={action}>{action}</option>)}
                            </Form.Control>
                        </td>
                        <td>
                            <Form.Control type="text"
                                placeholder="Target"
                                value={newTarget ?? ""}
                                onChange={e => setNewTarget(e.target.value)} />
                        </td>
                        <td>
                            <Button variant="primary" onClick={addOnClick}>Add</Button>
                        </td>
                    </tr>
                </tbody>
            </Table>
        );

    return (
        <Card>
            <Card.Header>
                <Accordion.Toggle className="border-less"
                    as={Card.Header}
                    eventKey={props.scope.scopeOption.name}>
                    <Row>
                        <Col>
                            {props.scope.scopeOption.name}
                        </Col>
                        <Col xs={6} className="per-line">
                            {props.scope.actions && ActionsToCSV(props.scope.actions)}
                        </Col>
                        <Col>
                            <Button className="right"
                                variant="outline-danger"
                                size="sm"
                                onClick={deleteScopeHandler}>Delete</Button>
                        </Col>
                    </Row>
                </Accordion.Toggle>
            </Card.Header>
            <Accordion.Collapse eventKey={props.scope.scopeOption.name}>
                <Card.Body>
                    {actionsTable}
                </Card.Body>
            </Accordion.Collapse>
        </Card>
    );
}

export default ScopeElement;