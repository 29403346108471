import axios from "axios";
import {
    AccessTokenModel,
    ActionModel,
    ScopeModel,
    ScopeOptionModel,
    UserModel
} from "@medo/sso-libs";
import { SSO_URL } from "../App";

export default {
    async Logout() {
        try {
            const url = `${SSO_URL}/api/logout`;
            const response = await axios.get(url, { withCredentials: true })
            if (response.status !== 200) return false;
            return true;
        } catch (error) {
            return false
        }
    },
    async GetUsers(): Promise<UserModel[]> {
        const url = `${SSO_URL}/api/users`;
        const response = await axios.get<UserModel[]>(url, { withCredentials: true });

        return response.data;
    },
    async GetUser(userId: string) {
        try {
            const url = `${SSO_URL}/api/users/${userId}`;
            const response = await axios.get<UserModel | null>(url, { withCredentials: true });
            return response.data;
        } catch (error) {
            return null;
        }
    },
    async AddUser(username: string, password?: string) {
        const requestModel = {
            username,
            password
        };
        const url = `${SSO_URL}/api/users`;
        const response = await axios.post<UserModel>(url, requestModel, { withCredentials: true });
        return response.data;
    },
    async EditUser(id: string, username: string) {
        const userObject = {
            username
        };
        const url = `${SSO_URL}/api/users/${id}`;
        const response = await axios.patch<UserModel>(url, userObject, { withCredentials: true });
        return response.data;
    },
    async DeleteUser(id: string) {
        const url = `${SSO_URL}/api/users/${id}`;
        const response = await axios.delete<boolean>(url, { withCredentials: true });
        return response.data;
    },
    async ChangePassword(userId: string, currentPassword: string, newPassword: string) {
        type error = {
            error: string
        }
        const requestModel = {
            userId,
            currentPassword,
            newPassword
        };
        const url = `${SSO_URL}/api/changePassword`;
        const response = await axios.post<UserModel | error>(url, requestModel, { withCredentials: true });

        if ((response.data as error).error !== undefined) return (response.data as error).error;
        else return response.data as UserModel;
    },
    async IsUsernameAvailable(username: string) {
        const requestModel = {
            username
        };
        const url = `${SSO_URL}/api/isUsernameAvailable`;
        const response = await axios.post<boolean>(url, requestModel, { withCredentials: true });
        return response.data;
    },
    async GetAccessTokens(userId: string) {
        const url = `${SSO_URL}/api/accessTokens/${userId}`;
        const response = await axios.get<AccessTokenModel[]>(url, { withCredentials: true });
        return response.data;
    },
    async GenerateNewAccessToken(userId: string, description: string) {
        const requestModel = {
            userId,
            description
        };
        const url = `${SSO_URL}/api/accessTokens`;
        const response = await axios.post<AccessTokenModel>(url, requestModel, { withCredentials: true });
        return response.data;
    },
    async DeleteAccessToken(accessTokenId: string) {
        const url = `${SSO_URL}/api/accessTokens/${accessTokenId}`;
        const response = await axios.delete<boolean>(url, { withCredentials: true });
        return response.data;
    },
    async GetScopeOptions() {
        const url = `${SSO_URL}/api/scopeOptions`;
        const response = await axios.get<ScopeOptionModel[]>(url, { withCredentials: true });
        return response.data;
    },
    async AddScopeOption(name: string) {
        const requestModel = {
            name
        };
        const url = `${SSO_URL}/api/scopeOptions`;
        const response = await axios.post<ScopeOptionModel>(url, requestModel, { withCredentials: true });
        return response.data;
    },
    async EditScopeOption(id: string, name: string) {
        const requestModel = {
            name
        };

        const url = `${SSO_URL}/api/scopeOptions/${id}`;
        const response = await axios.patch<ScopeOptionModel | null>(url, requestModel, { withCredentials: true });
        if (response.data?.id.length !== 0) return response.data;
        else return null;
    },
    async DeleteScopeOption(appScopeId: string) {
        const url = `${SSO_URL}/api/scopeOptions/${appScopeId}`;
        const response = await axios.delete<boolean>(url, { withCredentials: true });
        return response.data;
    },
    async IsAppScopeAvailable(name: string) {
        const requestModel = {
            name
        };
        const url = `${SSO_URL}/api/isScopeOptionAvailable`;
        const response = await axios.post<boolean>(url, requestModel, { withCredentials: true });
        return response.data;
    },
    async AddScope(userId: string, scopeName: string,) {
        const requestModel = {
            userId,
            scopeName
        };

        const url = `${SSO_URL}/api/scopes`;
        const response = await axios.post<ScopeModel>(url, requestModel, { withCredentials: true });
        return response.data;
    },
    async DeleteScope(id: string,) {
        const url = `${SSO_URL}/api/scopes/${id}`;
        const response = await axios.delete<boolean>(url, { withCredentials: true });
        return response.data;
    },
    async AddAction(scopeId: string, method: string, target: string,) {
        const requestModel = {
            scopeId,
            actionModel: {
                method,
                target
            }
        };

        const url = `${SSO_URL}/api/actions`;
        const response = await axios.post<ActionModel>(url, requestModel, { withCredentials: true });
        return response.data;
    },
    async EditAction(id: string, method: string, target: string,) {
        const requestModel = {
            actionModel: {
                method,
                target
            }
        };

        const url = `${SSO_URL}/api/actions/${id}`;
        const response = await axios.patch<ActionModel>(url, requestModel, { withCredentials: true });
        return response.data;
    },
    async DeleteAction(id: string,) {
        const url = `${SSO_URL}/api/actions/${id}`;
        const response = await axios.delete<boolean>(url, { withCredentials: true });
        return response.data;
    }
}