import * as React from "react";
import * as ReactDOM from "react-dom";
import { BrowserRouter } from "react-router-dom";
import "./main.scss";
import App from "./App";
import { Provider } from "react-redux";
import store from "./store/store";
ReactDOM.render(
    <BrowserRouter basename="/">
        <Provider store={store}>
            <App />
        </Provider>
    </BrowserRouter>,
    document.getElementById("root")
);
