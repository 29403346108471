import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { Button, ButtonGroup, Dropdown, ListGroup } from 'react-bootstrap';
import { ScopeModel, UserModel } from "@medo/sso-libs";
import Repository from '../../common/Repository';
import BootstrapTable from "react-bootstrap-table-next";
import moment from 'moment';

// Issue with Webpack 5. Workaround -> https://github.com/react-bootstrap-table/react-bootstrap-table2/pull/1506
import ToolkitProvider, { Search } from 'react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit.min';
import { useDispatch } from 'react-redux';
import { AppDispatch } from '../../store/store';
import { uiActions } from '../../store/uiSlice';

interface Props {
    onClickNewUser: () => void,
    onClickManageScopes: () => void,
    onClickUserItem: (userId: string) => void
}
const Users: React.FC<Props> = props => {
    const [users, setUsers] = useState<UserModel[]>([]);
    const dispatch = useDispatch<AppDispatch>();

    useEffect(() => {
        Repository.GetUsers().then(fetchedUsers => {
            fetchedUsers.sort((a, b) => b.createdAt! - a.createdAt!);
            setUsers(fetchedUsers);
        }).catch(error => {
            if (axios.isAxiosError(error) && error.response?.status === 403) {
                dispatch(uiActions.setError("You are not authorized to view the list of users"));
            }
        })
    }, [])

    const columns = [
        {
            dataField: 'username',
            text: 'Username',
            formatter: (_, user: UserModel) =>
                <a href="#" onClick={() => props.onClickUserItem(user.id)}>{user.username}</a>,
            headerClasses: 'username-col'
        },
        {
            dataField: 'scopes',
            text: 'Scopes',
            formatter: (_, user: UserModel) => user.scopes != null ? ScopsToCSV(user.scopes) : "",
            headerClasses: 'scope-col'
        },
        {
            dataField: 'createdAt',
            text: 'Created At',
            formatter: date => date ? moment.unix(date).format("YYYY-MMM-DD") : "",
            headerClasses: 'date-col'
        }
    ];
    const { SearchBar } = Search;
    return (
        <ToolkitProvider
                keyField="id"
                data={users}
                columns={columns}
                bootstrap4
                search>
            {
                toolkitProps => (
                    <div>
                        <div className="row">
                            <div className="col">
                                {users.length > 0 ? <SearchBar {...toolkitProps.searchProps} /> : <></>}
                            </div>
                            <div className="col">

                            </div>
                            <div className="col text-align-right">
                                <Dropdown as={ButtonGroup}>
                                    <Button className="inline-block"
                                        variant="primary"
                                        onClick={props.onClickNewUser}>Add New User</Button>

                                    <Dropdown.Toggle split variant="primary" id="dropdown-split-basic" />

                                    <Dropdown.Menu>
                                        <Dropdown.Item onClick={props.onClickManageScopes}>
                                            Manage Scope Options
                                    </Dropdown.Item>
                                    </Dropdown.Menu>
                                </Dropdown>
                            </div>
                        </div>
                        <hr />
                        {users.length > 0 ? <BootstrapTable {...toolkitProps.baseProps} /> :
                            <p className="text-center">No Users Found 😴</p>}
                    </div>
                )
            }
        </ToolkitProvider>
    )
}

export default Users;

const ScopsToCSV = (scopes: ScopeModel[]) => {
    let result = "";
    scopes.forEach(scope => {
        result = result.length < 1 ? scope.scopeOption.name : `${result}, ${scope.scopeOption.name}`;
    });
    return result;
}